// js
AOS.init();

$(document).ready(function() {

  $(".hamburger").click(function() {
    $(this).toggleClass("is-active");
    $(".box-menu").toggleClass("open");
    // $("header").toggleClass("open-menu");
    // $("html").toggleClass("hidden-overflow");

  });

  $(".hamburger").click(function(e) {
    e.stopPropagation();
  });

  $(document).click(function(e) {
    if ($(e.target).is(".box-menu") === false) {
      $(".box-menu").removeClass("open");
      $('.hamburger').removeClass("is-active");
      // $("header").removeClass("open-menu");
      // $("html").removeClass("hidden-overflow");
    }
  });





  // Add smooth scrolling to all links
  $("a").on('click', function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();
      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 1000, function(){

        // Add hash (#) to URL when done scrolling (default click behavior)
        // window.location.hash = hash;
      });
    } // End if
  });

  // var screen_width = $(window).width();
  // var screen_height = $(window).height();
  // var set_pin_x = screen_width / 2;
  // var set_pin_y = screen_height / 2;
  // var pin_1_position = 'x:'+set_pin_x+';y:'+set_pin_y+'; force3D:true;';
  //
  // $('#pin-1').attr('data-options', pin_1_position);


  $("#pz1").on("wheel", function(){
    $('.float-logos, footer, .wrap-time-line').addClass('hide');

    if ( $('.zoomOut').hasClass('off') ) {
      $('.float-logos, footer, .wrap-time-line').removeClass('hide');
    }
  });



  $(function(){

    $('.zoomIn, .zoomOut').click(function(){
      $('.float-logos, footer, .wrap-time-line').addClass('hide');
      if ($('.zoomOut').hasClass('off')) {
        $('.float-logos, footer, .wrap-time-line').removeClass('hide');
      }
    });

  });

  if ( $('body.search').length ) {

    // $(".mapa-home").one("load", function() {
    //   console.log('carrengando...');
    // }).each(function() {
    //   if(this.complete) {
    //     $(this).trigger('load');
    //     $('.img-placeholder').addClass('d-none');
    //     $('.mapa-home').removeClass('d-none');
    //     console.log('carregou tudo!');
    //   }
    // });

    if (window.location.href.indexOf("filter_year=") > -1) {

      var myURL = window.location.href;
      var pathArray = myURL.split( 'filter_year=' );
      // console.log(pathArray);

      var atributo = pathArray[1];
      var pathArray2 = atributo.split( '.' );

      var t_anos_init = parseInt(pathArray2[1]);
      var t_anos_finish = parseInt(pathArray2[2]);


    } else {
      var t_anos_init = 1910;
      var t_anos_finish = 1989;
    }


    // External js: jquery, isotope.pkgd.js, bootstrap.min.js, bootstrap-slider.js
    // Create object to store filter for each group
    var buttonFilters = {};
    var buttonFilter = '*';
    // Create new object for the range filters and set default values,
    // The default values should correspond to the default values from the slider
    var t_anos_init = t_anos_init;
    var t_anos_finish = t_anos_finish;
    var rangeFilters = {
      // 'height': {'min':150, 'max': 185},
      'weight': {'min':t_anos_init, 'max': t_anos_finish}
    };

    // function getHashFilter() {
    //   // get filter=filterName
    //   var matches = location.hash.match( /filter=([^&]+)/i );
    //   var hashFilter = matches && matches[1];
    //   return hashFilter && decodeURIComponent( hashFilter );
    // }

    // Initialise Isotope

    // var isIsotopeInit = false;

    // function onHashchange() {
    //   var hashFilter = getHashFilter();
    //   if ( !hashFilter && isIsotopeInit ) {
    //     return;
    //   }
    //   isIsotopeInit = true;

    if ( $(window).width() > 992 ) {
      var initial_items = 9;
      var next_items = 9;
    } else {
      var initial_items = 5;
      var next_items = 5;
    }
    // var initial_items = 9;
    // var next_items = 9;
    // Set the item selector
    var $grid = $('.grid').isotope({
      itemSelector: '.item',
      layoutMode: 'fitRows',
      transitionDuration: 0,
      // hiddenStyle: {
      //   opacity: 0
      // },
      // visibleStyle: {
      //   opacity: 1
      // },
      // layout: 'masonry',
      // use filter function
      filter: function() {
        var $this = $(this);
        // var height = $this.attr('data-height');
        var weight = $this.attr('data-weight');
        // var isInHeightRange = (rangeFilters['height'].min <= height && rangeFilters['height'].max >= height);
        var isInWeightRange = (rangeFilters['weight'].min <= weight && rangeFilters['weight'].max >= weight);
        //console.log(rangeFilters['height']);
        //console.log(rangeFilters['weight']);
        // Debug to check whether an item is within the height weight range
        //console.log('isInHeightRange:' +isInHeightRange + '\nisInWeightRange: ' + isInWeightRange );
        return $this.is( buttonFilter ) && (isInWeightRange);
        // return $this.is( buttonFilter ) && (isInHeightRange && isInWeightRange);
      }
    });



    // Initialise Sliders
    // Set min/max range on sliders as well as default values
    // var $heightSlider = $('#filter-height').slider({ tooltip_split: true, min: 130,  max: 220, range: true, value: [150, 180] });
    var t_anos = [t_anos_init, t_anos_finish];
    var $weightSlider = $('#filter-weight').slider({ tooltip_split: true, min: 1910,  max: 1989, range: true, value: t_anos });


    function updateRangeSlider(slider, slideEvt) {
      // console.log('Current slider:' + slider);
      var sldmin = +slideEvt.value[0],
      sldmax = +slideEvt.value[1],
      // Find which filter group this slider is in (in this case it will be either height or weight)
      // This can be changed by modifying the data-filter-group="age" attribute on the slider HTML
      filterGroup = slider.attr('data-filter-group'),
      // Set current selection in variable that can be pass to the label
      currentSelection = sldmin + ' - ' + sldmax;
      currentSelection2 = '.'+ sldmin + '.' + sldmax;

      // Update filter label with new range selection
      slider.siblings('.filter-label').find('.filter-selection').text(currentSelection);

      // Set min and max values for current selection to current selection
      // If no values are found set min to 0 and max to 100000
      // Store min/max values in rangeFilters array in the relevant filter group
      // E.g. rangeFilters['height'].min and rangeFilters['height'].max
      // console.log('Filtergroup: '+ currentSelection2);
      rangeFilters[filterGroup] = {
        min: sldmin || 0,
        max: sldmax || 100000
      };
      // Trigger isotope again to refresh layout


      // location.hash = 'filter_year=' + encodeURIComponent( currentSelection2 ) + '&';

      var url = new URL(document.location);
      var search_params = url.searchParams;
      search_params.set('filter_year', encodeURIComponent(currentSelection2));
      url.search = search_params.toString();
      var new_url = url.toString();
      window.history.replaceState(null, null, new_url);
      // document.location.href = new_url;
      // console.log(new_url)

      $grid.isotope();
      updateFilterCounts();


    }

    // Trigger Isotope Filter when slider drag has stopped
    // $heightSlider.on('slideStop', function(slideEvt){
    //   var $this =$(this);
    //   updateRangeSlider($this, slideEvt);
    // });
    $weightSlider.on('slideStop', function(slideEvt){
      var $this =$(this);
      updateRangeSlider($this, slideEvt);
    });


    // Look inside element with .filters class for any clicks on elements with .btn
    var $filterButtonGroup = $('.filters');
    $filterButtonGroup.on( 'click', '.btn-filter', function() {
      var $this = $(this);
      // Get group key from parent btn-group (e.g. data-filter-group="color")
      var $buttonGroup = $this.parents('.btn-group');
      var filterGroup = $buttonGroup.attr('data-filter-group');
      // set filter for group
      buttonFilters[filterGroup] = $this.attr('data-filter');
      // Combine filters or set the value to * if buttonFilters
      buttonFilter = concatValues( buttonFilters ) || '*';
      // Log out current filter to check that it's working when clicked
      // console.log( buttonFilter )
      // location.hash = 'filter=' + encodeURIComponent( buttonFilter ) + '&';

      // console.log(buttonFilters[filterGroup]);


      var url = new URL(document.location);
      var search_params = url.searchParams;
      search_params.set('filter', encodeURIComponent( buttonFilter ));
      url.search = search_params.toString();
      var new_url = url.toString();
      window.history.replaceState(null, null, new_url);

      // console.log(new_url);

      // Trigger isotope again to refresh layout
      $grid.isotope();
      updateFilterCounts();
    });

    function updateFilterCounts() {
      // get filtered item elements
      var itemElems = $grid.isotope('getFilteredItemElements');
      var count_items = $(itemElems).length;

      if (count_items > initial_items) {
        $('.load-more').show();
      }
      else {
        $('.load-more').hide();
      }
      if ($('.item').hasClass('hidden')) {
        $('.item').removeClass('hidden');
      }
      var index = 0;

      $(itemElems).each(function () {
        if (index >= initial_items) {
          $(this).addClass('hidden');
        }
        index++;
      });
      $grid.isotope('layout');
    }


    // change is-checked class on btn-filter to toggle which one is active
    $('.btn-group').each( function( i, buttonGroup ) {
      var $buttonGroup = $( buttonGroup );
      $buttonGroup.on( 'click', '.btn-filter', function() {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
        $(this).addClass('is-checked');
      });
    });


    function showNextItems(pagination) {
      var itemsMax = $('.hidden').length;
      var itemsCount = 0;
      $('.hidden').each(function () {
        if (itemsCount < pagination) {
          $(this).removeClass('hidden');
          itemsCount++;
        }
      });
      if (itemsCount >= itemsMax) {
        $('.load-more').hide();
      }
      $grid.isotope('layout');
    }


    // function that hides items when page is loaded
    function hideItems(pagination) {
      var itemsMax = $('.item').length;
      var itemsCount = 0;
      $('.item').each(function () {
        if (itemsCount >= pagination) {
          $(this).addClass('hidden');
        }
        itemsCount++;
      });
      if (itemsCount < itemsMax || initial_items >= itemsMax) {
        $('.load-more').hide();
      }
      $grid.isotope('layout');
    }
    $('.load-more').on('click', function (e) {
      e.preventDefault();
      showNextItems(next_items);
    });
    hideItems(initial_items);


    // set selected class on button
    // if ( hashFilter ) {
    //   // $filterButtonGroup.find('.is-checked').removeClass('is-checked');
    //   // $filterButtonGroup.find('[data-filter="' + hashFilter + '"]').addClass('is-checked');
    // }
    // }
    // Flatten object by concatting values
    function concatValues( obj ) {
      var value = '';
      for ( var prop in obj ) {
        value += obj[ prop ];
      }
      return value;
    }


    if (window.location.href.indexOf("filter=") > -1) {

      var myURL = window.location.href;
      var pathArray = myURL.split( '&filter=' );
      console.log(pathArray);


      var atributo = pathArray[1];
      var pathArray2 = atributo.split( '&filter_year=' );
      console.log(pathArray2);

      var teste = pathArray2[0].split('.');



      var qtd_array = teste.length;

      for (var i = 0; i < qtd_array; i++) {
        var aa = '.btn-filter[data-filter=".'+teste[i]+'"]';
        console.log(aa)
        $('.btn-filter[data-filter=".'+teste[i]+'"]').click();

        // if ( $('.btn-filter').hasClass('is-checked') ){
        var texto_city = $('.btn-group[data-filter-group=city] .btn-filter.is-checked').text();
        $('.selected-city').text(texto_city);


        // }




        // console.log(pathArray2[i]);
      }



    }




    $('.limpar').click(function(){
      $('.btn-group .btn-limpar').click();
      $('.selected-city').text('Cidade');
    });

    $('.btn-cidade').click(function(e){
      $('.wrap-group-filters').toggleClass('open');
      e.stopPropagation();
    });
    $('.btn-trama').click(function(event){
      $('.wrapper-trama-de-palavras').toggleClass('open');
      event.stopPropagation();
    });

    $(document).click(function(e) {
      if ($(e.target).is(".wrap-group-filters") === false) {
        $('.wrap-group-filters').removeClass('open');
      }
    });

    $(document).click(function(event) {
      if ($(event.target).is(".wrapper-trama-de-palavras") === false) {
        $('.wrapper-trama-de-palavras').removeClass('open');
      }
    });

    $('.wrap-group-filters .btn-filter').click(function(){
      var texto = $(this).text();
      $('.selected-city').text(texto);
    });



    // $('.bootstrap-slider').on('slideStop', function(){
    //
    //
    //   var data = $('.min-slider-handle').attr('aria-valuenow');
    //   var data2 = $('.max-slider-handle').attr('aria-valuenow');
    //
    //   console.log(data);
    //
    //   if ( data <= 1914 ) {
    //     $('.tooltip-min .tooltip-inner').css('bottom', '5px');
    //   } else {
    //     $('.tooltip-min .tooltip-inner').css('bottom', '0');
    //   }
    //
    //   if ( data == 1910 ) {
    //     $('.tooltip-min .tooltip-inner').css('opacity', '0');
    //   } else {
    //     $('.tooltip-min .tooltip-inner').css('opacity', '1');
    //   }
    // });



  }




  if ($('.home').length) {

    // Initialise Sliders
    var t_anos_init = 1910;
    var t_anos_finish = 1989;
    var t_anos = [t_anos_init, t_anos_finish];

    $('.bootstrap-slider').slider({ tooltip_split: true, min: t_anos_init,  max: t_anos_finish, range: true, value: t_anos });


    $('.bootstrap-slider').on('slideStop', function(){


      $('.box-filme').addClass('animate');
      setTimeout(function(){
        $('.box-filme').removeClass('animate');
      }, 500);


      var data = $('.min-slider-handle').attr('aria-valuenow');
      var data2 = $('.max-slider-handle').attr('aria-valuenow');



      $('.box-filme').each(function(){

        var pin_href = $(this).find('.wrapper-marker a');
        // var pin_link = $(this).find('.wrapper-marker a').attr('href');
        var print_link_cidade = $(this).find('.wrapper-marker .hidden-city').text();
        pin_href.attr('href', print_link_cidade+'&filter_year=.'+data+'.'+data2);

        $(this).find('li.post-item').each(function(){

          var year = $(this).attr('data-year');
          if ( year >= data && year <= data2 ) {
            $(this).addClass('inside-range');
          } else {
            $(this).removeClass('inside-range');
          }

        });

        var count_post_item = $(this).find('li.post-item.inside-range').length;
        if ( count_post_item == 1 ) {
          $(this).find('.f-total').text(count_post_item + ' filme');
        } else {
          $(this).find('.f-total').text(count_post_item + ' filmes');
        }
        // if ( count_post_item == 0 ) {
        //   $(this).addClass('hide');
        // } else {
        //   $(this).removeClass('hide');
        // }
        // console.log(count_post_item);

      });

    });

    $('.box-filme').each(function(){

      var count_post_item = $(this).children("li").length;

      if ( count_post_item == 1 ) {
        $(this).find('.f-total').text(count_post_item + ' filme');
      } else {
        $(this).find('.f-total').text(count_post_item + ' filmes');
      }

    });


    $('.wrapper-marker').each(function(){

      var custom_w_marker = $(this).width();
      var calc_cwm = (custom_w_marker / 2) - 6;
      $(this).css('margin-left', '-'+calc_cwm+'px');

    });

    $('.ico-pin').click(function(){
      $(this).toggleClass('active');
      $('.marker').toggleClass('d-none');
    });

    $('.ico-filter').click(function(){
      $(this).toggleClass('active');
      $('.wrap-time-line').toggleClass('d-none');
    });

    $('.ico-img').click(function(){
      $(this).toggleClass('active');
      $('.float-logos, footer').toggleClass('d-none');
    });



  }

  if (!localStorage.getItem('aceite_termos_lgpd', true) ) {
    $('#modal-lgpd').modal('show');
    $('.btn-modal').click(function() {
      localStorage.setItem('aceite_termos_lgpd', true);
    });
  }



  $('.box-imgs-grid').masonry({
    itemSelector: '.box-img-grid-item',
    columnWidth: '.grid-sizer',
    percentPosition: true
  });


});
